import React from "react";
import { Button, Collapse, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { updatePack } from "../utils";
import { getColorWithMode } from "../constants/colors";
import { useMediaQuery } from "react-responsive";
import Markdown from "react-markdown";
import { Typography } from "@mui/material";
import { Link } from "@mui/material";

const PackDescription = ({
  packDescription,
  setPackDescription,
  tableId,
  shareable,
  darkMode,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 898 });
  const [expanded, setExpanded] = React.useState(true);
  const [temporaryDescription, setTemporaryDescription] = React.useState(
    packDescription || ""
  );

  const handleUpdatePackDescription = () => {
    const newData = { packDescription: temporaryDescription || "" };
    setPackDescription(temporaryDescription);
    updatePack(tableId, newData);
  };

  React.useEffect(() => {
    setTemporaryDescription(packDescription || "");
  }, [packDescription]);

  return !shareable || temporaryDescription.length ? (
    <>
      <Collapse
        in={expanded}
        collapsedSize={40}
        sx={{ marginTop: "5px !important" }}
      >
        <Box
          sx={{
            borderRadius: "10px",
            backgroundColor: darkMode ? "#2A2A2A" : "#F0F0F0",
          }}
        >
          {shareable ? (
            <Markdown children={temporaryDescription} />
          ) : (
            <TextField
              multiline
              variant="standard"
              value={temporaryDescription}
              sx={{
                width: "100%",
                padding: "5px 5px 5px 10px",
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: getColorWithMode(darkMode, "text"),
                },
              }}
              InputProps={{
                disableUnderline: true,
                readOnly: shareable,
                sx: {
                  fontSize: isMobile ? "24px" : "16px",
                },
              }}
              inputProps={{
                maxLength: 50000,
              }}
              placeholder={shareable ? "No Description" : "Description"}
              onFocus={() => {
                if (!expanded) {
                  setExpanded(true);
                }
              }}
              onChange={(e) => {
                if (!expanded) {
                  setExpanded(true);
                }
                setTemporaryDescription(e.target.value);
              }}
              onBlur={handleUpdatePackDescription}
            ></TextField>
          )}
        </Box>
        {shareable ? (
          <></>
        ) : (
          <Typography variant="subtitle2" sx={{ opacity: "0.8" }}>
            We fully support{" "}
            <Link
              rel="noopener noreferrer"
              target="_blank"
              href="https://docs.github.com/en/get-started/writing-on-github/getting-started-with-writing-and-formatting-on-github/basic-writing-and-formatting-syntax"
            >
              Markdown
            </Link>
          </Typography>
        )}
      </Collapse>
      {temporaryDescription.length > 0 ? (
        <Button
          sx={{
            position: "relative",
            padding: "0px",
            marginTop: "2px !important",
            fontSize: isMobile ? "20px" : "12px",
          }}
          onClick={() => {
            setExpanded(!expanded);
          }}
        >
          {`${expanded ? "collapse" : "expand"} description`}
        </Button>
      ) : (
        <Box sx={{ marginTop: "2px" }}></Box>
      )}
    </>
  ) : (
    <></>
  );
};

export default PackDescription;
